<template>
  <div class="eventList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{ height: 'auto' }"
        :data-items="kgm_computedGridItems(installationEvents)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationEvents)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode,
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateTemplate"
          slot-scope="{ props }"
        >
          <td>
            {{
              dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))
            }}
          </td>
        </template>
        <template
          slot="descriptionTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <span v-if="props.dataItem.description">
              {{
                props.dataItem.description.length > 100
                  ? props.dataItem.description.substring(0, 100) + "..."
                  : props.dataItem.description
              }}
            </span>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          />
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('nameFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="eventTypeFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="eventTypeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Type"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          />
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('eventTypeFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="descriptionFilter"
          slot-scope="{ props, methods }"
          class="input-group"
        >
          <input
            ref="descriptionFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Description"
            @input="
              (event) => {
                methods.change({
                  operator: 'contains',
                  field: props.field,
                  value: event.target.value,
                  syntheticEvent: event,
                });
              }
            "
          />
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="
                kgm_resetInput('descriptionFilterInput', (event) => {
                  methods.change({
                    operator: '',
                    field: '',
                    value: '',
                    syntheticEvent: event,
                  });
                })
              "
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{ props }"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editEvent(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t("edit") }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="
                  () => {
                    indexToDelete = props.dataItem.id;
                    statusToDelete = props.dataItem.statusId;
                    openConfirmRemoveModal();
                  }
                "
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
              <button      
                v-if="serviceId && authenticationHasRole('scope_staff')"
                :title="
                  props.dataItem.statusId
                    ? 'Unpublish on Status Page'
                    : 'Publish to Status Page'
                "
                class="btn btn-success btn-sm"
                @click="
                  () => {
                    indexToPublish = props.dataItem;
                    openConfirmPublishModal();
                  }
                "
              >
                <span>{{
                  props.dataItem.statusId ? "Unpublish" : "Publish"
                }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        :show-sidebar="showSidebar"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :installation-id="installationId"
          :event="currentEvent"
          :service-id="serviceId"
          :has-maintenance-setting="hasMaintenanceSetting"
          @reloadEvents="reloadEventsAndCloseSidebar"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete event?"
      class="overflowHidden"
      @close="openDeleteModal = false"
    >
      <p>Are you sure you want to delete this Event?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="
          $refs.confirmDelete.close();
          openDeleteModal = false;
        "
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t("cancel") }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t("delete") }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="confirmPublish"
      icon="warning"
      blocking
      :title="
        indexToPublish && indexToPublish.statusId ? 'Unpublish' : 'Publish'
      "
      class="overflowHidden"
    >
      <p v-if="indexToPublish && indexToPublish.statusId">
        Are you sure you want to unpublish this Event on
        https://alturos.freshstatus.io/?
      </p>
      <p v-else>
        Are you sure you want to publish this Event to
        https://alturos.freshstatus.io/?
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmPublish.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t("cancel") }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-success float-right mb-3"
        @click="publishEvent()"
      >
        <span>{{
          indexToPublish && indexToPublish.statusId ? "Unpublish" : "Publish"
        }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { authenticationMixin } from "@/mixins/authenticationMixin";

import AddInstallationEvent from "@/components/Installation/AddInstallationEvent.vue";
import EditInstallationEvent from "@/components/Installation/EditInstallationEvent.vue";
import Sidebar from "@/components/Base/Sidebar.vue";

import { SweetModal } from "sweet-modal-vue";

export default {
  name: "InstallationEventList",
  components: {
    Sidebar,
    AddInstallationEvent,
    EditInstallationEvent,
    SweetModal,
  },
  mixins: [kendoGridMixin, dateTimeMixin, authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    past: {
      type: Boolean,
      default: false,
    },
    serviceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      installationEvents: null,
      indexToPublish: null,
      showSidebar: false,
      currentEvent: null,
      currentComponent: null,
      indexToDelete: null,
      statusToDelete: null,
      hasMaintenanceSetting: false,
      openDeleteModal: false,
      kgm_columns: [
        {
          field: "name",
          filterable: true,
          filter: "text",
          title: "Name",
          filterCell: "nameFilter",
        },
        {
          field: "eventType",
          filterable: true,
          filter: "text",
          title: this.$t("eventType"),
          filterCell: "eventTypeFilter",
        },
        {
          field: "startDate",
          filterable: true,
          filter: "date",
          title: this.$t("startDate"),
          cell: "dateTemplate",
        },
        {
          field: "endDate",
          filterable: true,
          filter: "date",
          title: this.$t("endDate"),
          cell: "dateTemplate",
        },
        {
          field: "description",
          filterable: true,
          filter: "text",
          title: this.$t("description"),
          cell: "descriptionTemplate",
          filterCell: "descriptionFilter",
        },
        {
          filterable: false,
          title: "Options",
          cell: "optionsTemplate",
          hidden: !(
            this.authenticationHasRole("crud_events") ||
            (this.authenticationHasRole("scope_staff") &&
              (this.authenticationHasRole("secondlevel") ||
                this.authenticationHasRole("admin") ||
                this.authenticationHasRole("firstlevel")))
          ),
        },
      ],
    };
  },
  watch: {
    past() {
      this.getInstallationEvents();
    },
  },
  created() {
    this.getSettingCheck();
    this.getInstallationEvents();
  },
  methods: {
    openConfirmRemoveModal() {
      this.$refs.confirmDelete.open();
      this.openDeleteModal = true;
    },
    openConfirmPublishModal() {
      this.$refs.confirmPublish.open();
    },
    //#region Sidebar
    show() {
      this.showSidebar = true;
    },
    hideSidebar() {
      this.showSidebar = false;
      this.currentComponent = null;
    },
    addEvent() {
      this.currentComponent = "AddInstallationEvent";
      this.show();
    },
    editEvent(emitValues) {
      this.currentComponent = "EditInstallationEvent";
      this.currentEvent = Object.assign({}, emitValues);
      this.show();
    },
    //#endregion
    //#region API-calls
    getInstallationEvents() {
      this.loading = true;
      this.axios
        .get(
          `/Installation/GetInstallationEvents?installationId=${this.installationId}&past=${this.past}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationEvents = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSettingCheck() {
      this.axios
        .get(
          `/Installation/CheckIfMaintenanceSetting?installationId=${this.installationId}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.hasMaintenanceSetting = response.data;
        });
    },
    async removeEvent() {
      this.loading = true;
      await this.axios
        .post(
          `/Freshdesk/RemoveInstallationEvent?eventId=${this.indexToDelete}&statusId=${this.statusToDelete}`,
          {}
        )
        .then(() => {
          this.reloadEventsAndCloseSidebar();
          this.$snotify.success(
            this.$t("installationEvent.deletedSuccessfully")
          );
        })
        .finally(() => {
          this.indexToDelete = null;
          this.statusToDelete = null;
          this.$refs.confirmDelete.close();
        });
    },
    publishEvent() {
      this.loading = true;
      this.$refs.confirmPublish.close();
      this.axios
        .post(
          `/Freshdesk/PublishEvent?serviceId=${this.serviceId}`,
          this.indexToPublish
        )
        .then(() => {
          if (this.serviceId) {
            this.$snotify.success(
              this.$t("installationEvent.unpublishedSuccessfully")
            );
          } else {
            this.$snotify.success(
              this.$t("installationEvent.publishedSuccessfully")
            );
          }
        })
        .finally(() => {
          this.indexToPublish = null;
          this.getInstallationEvents();
        })
        .catch(() => (this.loading = false));
    },
    reloadEventsAndCloseSidebar() {
      this.hideSidebar();
      this.getInstallationEvents();
      this.$emit("reloadAuditLogs");
    },
    //#endregion
  },
};
</script>
<style>
.eventList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
